export default [
  {
    title: 'Tableau de bord',
    route: 'espace-admin.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des certificats',
    icon: 'ListIcon',
  },
  {
    title: 'Créer un certificat',
    route: 'espace-admin.ajouter-certificat',
    icon: 'PlusIcon',
  },
  {
    title: 'Liste des certificats',
    route: 'espace-admin.list-certificats',
    icon: 'FileIcon',
  },
  {
    header: 'Gestion des documents',
    icon: 'ListIcon',
  },
  {
    title: 'Liste des documents',
    route: 'espace-admin.list-types-docs',
    icon: 'FileTextIcon',
  },
  {
    header: 'Gestion du personnel',
    icon: 'ListIcon',
  },
  {
    title: 'Liste du personnel',
    route: 'espace-admin.list-personnels',
    icon: 'UsersIcon',
  },

]
